<template>
  <dv-full-screen-container class="bg">
    <!-- 顶部 -->
    <Header
      pageNum="2/7"
      :villageName="detail.village_name"
      :slogan="detail.slogan"
      :curCountDown="curCountDown"
      @onShowNext="onShowNext"
    />
    <div class="main">
      <div class="m__part1">
        <div class="m__part1-top">
          <div class="m__title">规划</div>
          <div class="guihua">
            <span>【发展目标】: </span>{{ detail.orientation }}
          </div>
          <div class="guihua-img">
            <van-swipe
              class="my-swipe"
              :autoplay="3000"
              indicator-color="white"
            >
              <van-swipe-item v-for="(item, index) in imgList" :key="index">
                <img :src="item.cover_img" alt="" />
              </van-swipe-item>
            </van-swipe>
            <!-- <img
              src="../assets/survey/规划地图@2x.png"
              alt=""
            > -->
          </div>
        </div>
        <div class="m__part1-bottom">
          <div class="m__title">规划项目</div>
          <div>
            <dv-scroll-board :config="config1" class="pb1__list" />
          </div>
        </div>
      </div>
      <div class="m__part2">
        <div class="m__part2-top">
          <div class="pt2__content">
            <div class="left-content">
              <div class="icon-box">
                <div class="left icon1"></div>
                <div class="right">
                  <div class="name">人口</div>
                  <div class="value">{{ detail.population }}户</div>
                </div>
              </div>
              <div class="icon-box">
                <div class="left icon2"></div>
                <div class="right">
                  <div class="name">户数</div>
                  <div class="value">{{ detail.household_register }}户</div>
                </div>
              </div>
              <div class="icon-box">
                <div class="left icon3"></div>
                <div class="right">
                  <div class="name">组数</div>
                  <div class="value">7个</div>
                </div>
              </div>
            </div>
            <div class="line"></div>
            <div class="right-content">
              <div class="icon-box">
                <div class="left icon4"></div>
                <div class="right">
                  <div class="name">男</div>
                  <div class="value">{{ detail.male_population }}人</div>
                </div>
              </div>
              <div class="icon-box" style="margin-top: 20px">
                <div class="left icon5"></div>
                <div class="right">
                  <div class="name">女</div>
                  <div class="value">{{ detail.female_population }}人</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="m__part2-middle1">
          <div class="pm2__content">
            <div class="icon-box">
              <div class="left icon6"></div>
              <div class="right">
                <div class="name">党支部</div>
                <div class="value">5个</div>
              </div>
            </div>
            <div class="line"></div>
            <div class="icon-box">
              <div class="left icon7"></div>
              <div class="right">
                <div class="name">党员</div>
                <div class="value">{{ dangyuan }}人</div>
              </div>
            </div>
          </div>
        </div>
        <div class="m__part2-middle2">
          <div class="pm2__content">
            <div class="icon-box">
              <div class="left icon8"></div>
              <div class="right">
                <div class="name">GDP</div>
                <div class="value">{{ detail.gdp }}亿元</div>
              </div>
            </div>
            <div class="line"></div>
            <div class="icon-box">
              <div class="left icon9"></div>
              <div class="right">
                <div class="name">人均GDP</div>
                <div class="value">{{ detail.per_gdp }}万元</div>
              </div>
            </div>
          </div>
        </div>
        <div class="m__part2-bottom">
          <div class="m__title">简介</div>
          <div
            class="pb2__content"
            v-html="detail.introduction"
            id="introduction"
          ></div>
        </div>
      </div>
      <div class="m__part3">
        <div class="m__part3-top">
          <div class="m__title">两委班子</div>
          <div class="pt3__content" id="leader">
            <div
              class="people-box"
              v-for="(item, index) in leaderList"
              :key="index"
            >
              <div
                class="image"
                :style="`background: url('${item.face_url}') no-repeat;background-size: 100% 100%`"
              ></div>
              <div class="name">{{ item.name }}</div>
              <div class="job">{{ item.position }}</div>
            </div>
            <!-- <div class="people-box">
              <div class="image img2"></div>
              <div class="name">孙孝元</div>
              <div class="job">党委副书记、村委会主任</div>
            </div>
            <div class="people-box">
              <div class="image img3"></div>
              <div class="name">陈昌登</div>
              <div class="job">党委副书记、村委会主任</div>
            </div>
            <div class="people-box">
              <div class="image img4"></div>
              <div class="name">孙孝元</div>
              <div class="job">党委副书记、村委会主任</div>
            </div> -->
          </div>
        </div>
        <div class="m__part3-bottom">
          <div class="m__title">荣誉</div>
          <div class="pb3__content" v-html="detail.honor" id="honor">
            <!-- <div
              class="honor"
              v-for="(item, index) in honors"
              :key="index"
            >
              <div class="td1">{{item.date}}</div>
              <div class="td2">{{item.level}}</div>
              <div class="td3">{{item.content}}</div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="code f-ai-c">
        <div class="f-1">
          <div class="txt f-ai-c">
            <img class="view" src="../assets/industry/查看@2x.png" alt="" />
            {{ scanConfig.follow_count }}人
          </div>
          <div class="txt f-ai-c mt-10">
            <img class="click" src="../assets/industry/点击@2x.png" alt="" />
            {{ scanConfig.visit_count || 0 }}次
          </div>
        </div>
        <img class="icon" :src="scanConfig.qrcode_img" alt="" />
      </div>
    </div>
  </dv-full-screen-container>
</template>

<script>
import {
  getPartyMemberList,
  getHomepage,
  getCunLiangWei,
  getRoadMap,
} from "@/api/survey/index";
import axios from "axios";
import { getConfig } from "@/api/village";
import Header from "./components/Header";
export default {
  components: {
    Header,
  },
  data() {
    return {
      config: {
        header: ["姓名", "级别", "届数"],
        data: [
          ["行1列1", "行1列2", "行1列3"],
          ["行2列1", "行2列2", "行2列3"],
          ["行3列1", "行3列2", "行3列3"],
          ["行4列1", "行4列2", "行4列3"],
          ["行5列1", "行5列2", "行5列3"],
          ["行6列1", "行6列2", "行6列3"],
          ["行7列1", "行7列2", "行7列3"],
          ["行8列1", "行8列2", "行8列3"],
          ["行9列1", "行9列2", "行9列3"],
          ["行5列1", "行5列2", "行5列3"],
          ["行6列1", "行6列2", "行6列3"],
          ["行7列1", "行7列2", "行7列3"],
          ["行8列1", "行8列2", "行8列3"],
          ["行9列1", "行9列2", "行9列3"],
          ["行10列1", "行10列2", "行10列3"],
          ["行10列1", "行10列2", "行10列3"],
          ["行10列1", "行10列2", "行10列3"],
          ["行10列1", "行10列2", "行10列3"],
          ["行10列1", "行10列2", "行10列3"],
          ["行10列1", "行10列2", "行10列3"],
          ["行10列1", "行10列2", "行10列3"],
          ["行10列1", "行10列2", "行10列3"],
        ],
        headerBGC: "#040b13",
        evenRowBGC: "#164A55",
        oddRowBGC: "#040b13",
        align: ["center", "center", "center"],
      },
      config1: {
        header: [
          "序号",
          "项目名称",
          "出资主体",
          "预算",
          "计划完工日期",
          "进度",
        ],
        data: [
          [1, "行1列1", "行1列2", "行1列3", "2010年12月", "30%"],
          [2, "行2列1", "行2列2", "行2列3", "2010年12月", "30%"],
          [3, "行3列1", "行3列2", "行3列3", "2010年12月", "30%"],
          [4, "行4列1", "行4列2", "行4列3", "2010年12月", "30%"],
          [5, "行5列1", "行5列2", "行5列3", "2010年12月", "30%"],
          [6, "行6列1", "行6列2", "行6列3", "2010年12月", "30%"],
          [7, "行7列1", "行7列2", "行7列3", "2010年12月", "30%"],
          [8, "行8列1", "行8列2", "行8列3", "2010年12月", "30%"],
        ],
        headerBGC: "#040b13",
        evenRowBGC: "#164A55",
        oddRowBGC: "#040b13",
        align: ["center", "center", "center", "center", "center", "center"],
        rowNum: 4,
        columnWidth: [80, 300, 140, 140, 140, 110],
      },
      honors: [
        { date: "2017年6月", level: " 镇级", content: "先进基层党组织" },
        { date: "2017年6月", level: " 镇级", content: "先进基层党组织" },
        { date: "2017年6月", level: " 镇级", content: "先进基层党组织" },
        { date: "2017年6月", level: " 镇级", content: "先进基层党组织" },
        { date: "2017年6月", level: " 镇级", content: "先进基层党组织" },
        { date: "2017年6月", level: " 镇级", content: "先进基层党组织" },
        { date: "2017年6月", level: " 镇级", content: "先进基层党组织" },
        { date: "2017年6月", level: " 镇级", content: "先进基层党组织" },
        { date: "2017年6月", level: " 镇级", content: "先进基层党组织" },
      ],
      villageId: "",
      category: "",
      dangyuan: "", //党员
      detail: {},
      leaderList: [],
      imgList: [],
      scrollTimerList: {}, // 滚动定时器
      scrollDirection: "down", // 滚动方向 up向上 down向下
      defaultDetail: {
        honor:
          "2017年6月   镇级    先进基层党组织；\n2018年2月   县级    2017年度先进单位；\n2019年6月   县级    先进基层党组织；\n2020年3月   县级    平安村（社区）。\n2020年6月   县级    2017-2019年度文明村\n2020年4月   省级    实绩突出村",
        introduction:
          "百胜村素有福州第一大行政村、中国缢蛏之乡、侨乡等美称。<br />村民主要从事渔业和海水养殖，主要产业为缢蛏、花蛤、紫菜等滩涂底播各类养殖作业。独特的地理环境造就了“百胜缢蛏”独特的品质，于2011年获国家工商总局颁发的地理标志商标称。相传最早百胜先民居住在大嶂山北侧糖园里,依山傍海,以猎渔耕作为生，在此繁行生息。随着人口增多,海洋滩涂裸露先民逐步搬迁山下海岸边最旱坐落于店头岸边制高处。最早小岭路(店头至樵园里),就是先民开辟的小山路,可通往东岱、关头、湖里等乡镇。由于百胜位于闽江口与敖江口入海交汇处，江两岸沙土流失和海水溶流冲积成沿海小平原为先民扩大居住区创诰白然环境。",
        orientation:
          "以传统古民居、革命老区红色文化以及古遗址文化为核心，以荔枝干、特色水果、有机蔬菜为核心产品的文旅及休闲农业一体的特色村落。",
        population: 11500,
        household_register: 3000,
        male_population: 6500,
        female_population: 5000,
        gdp: 0.8,
        per_gdp: 1.7,
        slogan: "百川汇海 胜在齐心",
        village_name: "百胜",
      },
      defaultImg: [
        {
          coverImg: require("../assets/survey/规划地图@2x.png"),
        },
        {
          coverImg: require("../assets/survey/规划地图@2x.png"),
        },
      ],
      id: "",
      curCountDown: 60,
      interval: null,
      scanConfig: {
        visit_count: 6,
        qrcode_img:
          "https://r.517lc.cn/Upload/WxQrCode/202011200030452461/73b9710771eb45a9886f7da242b8117d.png",
        countdown: 60,
      },
    };
  },
  created() {},
  mounted() {
    const id = this.$route.query.village_id || "6";
    this.id = id;
    this.getConfig(id);
    this.getDetail(id);
  },
  methods: {
    onShowNext() {
      clearInterval(this.interval);
      this.$router.push({
        path: "dynamic",
        query: { village_id: this.id },
      });
    },
    getDetail(id) {
      getHomepage({
        village_id: id,
      })
        .then((res) => {
          console.log(res);
          this.detail = res.data.data || this.defaultDetail;
        })
        .then(() => {
          this.$nextTick(() => {
            this.autoScroll("introduction");
            this.autoScroll("honor");
          });
        });
      getCunLiangWei({
        village_id: id,
        page: 1,
        rows: 10000,
      })
        .then((res) => {
          this.leaderList = res.data.data.pagedata || [];
          // this.leaderList = [
          //     {
          //         "term": "",
          //         "name": "吴明光",
          //         "position": "党委书记 ",
          //         "face_url": "https://r.517lc.cn/Upload/Tables/lc_village_light_body_item/202011062131487152/DSC_3011.jpg"
          //     }
          // ];
        })
        .then(() => {
          this.$nextTick(() => {
            this.autoScroll("leader");
          });
        });
      getPartyMemberList({
        villageId: id,
        category: "",
        sort: "",
        page: 1,
        rows: 10000,
      }).then((res) => {
        this.dangyuan = res.data.data.totalcount;
      });
      getRoadMap({
        village_id: id,
        page: 1,
      }).then((res) => {
        if (res.data.data.pagedata.length == 0) {
          this.imgList = this.defaultImg;
        } else {
          this.imgList = res.data.data.pagedata;
        }
      });
    },
    setTimer(time) {
      let that = this;
      that.curCountDown = time || 60;
      this.interval = setInterval(function () {
        that.curCountDown = that.curCountDown - 1;
        if (that.curCountDown <= 0) {
          clearInterval(that.interval);
          that.$router.push({
            path: "dynamic",
            query: { village_id: that.id },
          });
        }
      }, 1000);
    },
    async getConfig(id) {
      const { data } = await getConfig({
        village_id: id,
      });
      if (data.code === "200") {
        this.scanConfig = data.data;
        this.setTimer(data.data.countdown);
      }
      console.log("data", data);
    },
    autoScroll(box) {
      const scrollHeight = document.getElementById(box).scrollHeight;
      const clientHeight = document.getElementById(box).clientHeight;
      const scroll = scrollHeight - clientHeight;
      // 滚动长度为0
      if (scroll === 0) {
        return;
      }
      // 触发滚动方法
      this.scrollFun(box);
    },
    scrollFun(box) {
      // 如果定时器存在
      if (this.scrollTimerList[box]) {
        // 则先清除
        clearInterval(this.scrollTimerList[box]);
        this.scrollTimerList[box] = null;
      }
      this.scrollTimerList[box] = setInterval(() => {
        const scrollHeight = document.getElementById(box).scrollHeight;
        const clientHeight = document.getElementById(box).clientHeight;
        const scroll = scrollHeight - clientHeight;
        // 获取当前滚动条距离顶部高度
        const scrollTop = document.getElementById(box).scrollTop;
        // 向下滚动
        if (this.scrollDirection === "down") {
          const temp = scrollTop + 6;
          document.getElementById(box).scrollTop = temp; // 滚动
          // 距离顶部高度  大于等于 滚动长度
          if (scroll <= temp) {
            // 滚动到底部 停止定时器
            clearInterval(this.scrollTimerList[box]);
            this.scrollTimerList[box] = null;
            // 改变方向
            this.scrollDirection = "up";
            // 一秒后重开定时器
            setTimeout(() => {
              this.scrollFun(box);
            }, 1000);
          }
          // 向上滚动
        } else if (this.scrollDirection === "up") {
          const temp = scrollTop - 3;
          document.getElementById(box).scrollTop = temp; // 滚动
          // 距离顶部高度  小于等于 0
          if (temp <= 0) {
            // 滚动到底部 停止定时器
            clearInterval(this.scrollTimerList[box]);
            this.scrollTimerList[box] = null;
            // 改变方向
            this.scrollDirection = "down";
            // 一秒后重开定时器
            setTimeout(() => {
              this.scrollFun(box);
            }, 1000);
          }
        }
      }, 500);
    },
  },
  destroyed() {
    // 清理定时器
    Object.keys(this.scrollTimerList).forEach((key) =>
      clearInterval(this.scrollTimerList[key])
    );
  },
};
</script>
<style scoped>
.dv-scroll-board .header .header-item {
  color: #56fefe;
  font-size: 18px;
}
.dv-scroll-board .rows .row-item {
  font-size: 18px;
  height: h(40) !important;
  line-height: h(40) !important;
}
</style>
<style lang="scss" scoped>
.bg {
  background: #040b13;
  color: #ffffff;
}

.header1 {
  position: relative;
  width: 100%;
  height: h(101);
  background: url("../assets/imgs/big-title-bg.png") no-repeat;
  background-size: 100% 100%;
  padding: h(15) 47px 0 103px;
  display: flex;
  justify-content: space-between;

  &__left {
    line-height: h(33);
    font-size: 24px;
    color: #56fefe;
  }

  &__title {
    display: flex;
    align-items: center;
    font-size: 34px;
    font-weight: 400;
    color: #56f4fe;
    height: h(53);
    margin-left: 40px;
    // letter-spacing: 1px;
    // background: linear-gradient(180deg, #56f4fe 0%, #a3ffcd 100%);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;

    .name {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 126px;
      height: h(53);
      font-size: 32px;
      font-weight: 600;
      background: url("../assets/imgs/title-select.png") no-repeat;
      background-size: 126px h(53);
      margin-left: 10px;
    }
  }

  &__right {
    line-height: h(33);
    font-size: 24px;
    color: #56fefe;
  }
  &__page {
    display: inline-block;
    height: h(40);
    width: 96px;
    border: 1px solid #01f9ed;
    border-radius: 20px;
    line-height: h(40);
    text-align: center;
    font-size: 20px;
    background: #0c2b46;
    margin-left: 30px;
  }
}
.icon-box {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  .left {
    display: flex;
    width: 32px;
    height: 32px;
    margin-right: 13px;
    margin-left: 20px;
    background-size: 100% 100%;
  }
  .right {
    display: flex;
    flex-direction: column;
    width: 80px;
    .name {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 1px;
    }
    .value {
      font-size: 14px;
      line-height: 20px;
      color: #1deeff;
    }
  }
}
.main {
  position: relative;
  padding: h(25) 30px;
  display: flex;
  .m__title {
    width: 100%;
    height: h(38);
    line-height: h(30);
    color: #44ffff;
    font-size: 22px;
    background: url("../assets/character/Vector 66备份 7@2x.png") no-repeat;
    background-size: 100% h(4);
    background-position: center bottom;
  }
  .m__part1 {
    width: 988px;
    &-top {
      width: 100%;
      height: h(643);
      background: url("../assets/survey/规划外框@2x.png") no-repeat;
      background-size: 100% 100%;
      padding: h(21) 25px;
    }
    &-bottom {
      width: 100%;
      height: h(268);
      background: url("../assets/survey/规划项目外框@2x.png") no-repeat;
      background-size: 100% 100%;
      margin-top: 18px;
      padding: h(21) 25px;
    }
    .pb1__list {
      width: 100%;
      height: h(171) !important;
      margin-top: 10px;
      overflow: hidden;
    }
  }
  .m__part2 {
    width: 432px;
    margin-left: 18px;
    &-top {
      width: 100%;
      height: h(212);
      background: url("../assets/survey/人口外框@2x.png") no-repeat;
      background-size: 100% 100%;
      padding: h(21) 25px;
    }
    .pt2__content {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      position: relative;
      .left-content {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 50%;
        height: 100%;
      }
      .line {
        position: absolute;
        width: 1.33px;
        height: 82.8px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: rgba(255, 255, 255, 0.5);
      }
      .right-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        height: 100%;
      }
      // align-content: center;
      // justify-content: center;
    }
    .pm2__content {
      width: 100%;
      height: 100%;
      display: flex;
      position: relative;
      .line {
        position: absolute;
        width: 1.33px;
        height: 45.6px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: rgba(255, 255, 255, 0.5);
      }
    }
    &-middle1 {
      width: 100%;
      height: h(94);
      background: url("../assets/survey/党外框@2x.png") no-repeat;
      background-size: 100% 100%;
      margin-top: 18px;
      padding: h(21) 25px;
    }
    &-middle2 {
      width: 100%;
      height: h(94);
      background: url("../assets/survey/GDP外框@2x.png") no-repeat;
      background-size: 100% 100%;
      margin-top: 18px;
      padding: h(21) 25px;
    }
    &-bottom {
      width: 100%;
      height: h(475);
      background: url("../assets/survey/简介外框@2x.png") no-repeat;
      background-size: 100% 100%;
      margin-top: 18px;
      padding: h(21) 25px;
      .pb2__content {
        height: h(370);
        margin-top: 20px;
        font-size: 18px;
        line-height: 25px;
        overflow-y: hidden;
      }
    }
  }
  .m__part3 {
    width: 416px;
    margin-left: 18px;
    &-top {
      width: 100%;
      height: h(516);
      background: url("../assets/survey/两委班子外框@2x.png") no-repeat;
      background-size: 100% 100%;
      padding: h(21) 25px;
      .pt3__content {
        width: 100%;
        height: h(436);
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        padding-top: 3px;
        overflow-y: hidden;
        .people-box {
          display: flex;
          flex-direction: column;
          width: 50%;
          align-items: center;
          margin-top: 9px;
          .image {
            width: 130px;
            height: h(154);
            background-size: 100% 100%;
          }
          .name {
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 7px;
            margin-top: 7px;
          }
          .job {
            font-size: 12px;
            line-height: 17px;
          }
        }
      }
    }
    &-bottom {
      width: 100%;
      height: h(394);
      background: url("../assets/survey/荣誉外框@2x.png") no-repeat;
      background-size: 100% 100%;
      margin-top: 18px;
      padding: h(21) 25px;
      .pb3__content {
        width: 100%;
        height: h(314);
        display: flex;
        flex-direction: column;
        overflow-y: hidden;
        font-size: 16px;
        line-height: 22px;
        box-sizing: border-box;
        padding: 20px 0;
        .honor {
          display: flex;
          width: 100%;
          margin-top: 12px;
          .td1 {
            width: 30%;
            font-size: 16px;
            line-height: 22px;
          }
          .td2 {
            width: 80px;
            font-size: 16px;
            line-height: 22px;
          }
          .td3 {
            flex: 1;
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
    }
  }
  .p4-title {
    font-size: 22px;
    padding: h(20) 0 10px;
    font-weight: 600;
  }
  .NO {
    font-style: normal !important;
  }
  .p4-instr {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.8);
  }
  .code {
    position: absolute;
    width: 242px;
    height: h(130);
    padding: h(25) 20px;
    background: url("../assets/industry/浮窗_底板@2x.png");
    background-size: 100% 100%;
    right: 0;
    bottom: h(26);
    .txt {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.8);
      .view {
        width: 24px;
        height: h(17);
        margin-right: 10px;
      }
      .click {
        width: 24px;
        height: h(24);
        margin-right: 10px;
      }
    }
    .icon {
      width: 94px;
      height: h(94);
    }
  }
}
.icon1 {
  background: url("../assets/survey/人口图标@2x.png") no-repeat;
}
.icon2 {
  background: url("../assets/survey/户数图标@2x.png") no-repeat;
}
.icon3 {
  background: url("../assets/survey/组数图标@2x.png") no-repeat;
}
.icon4 {
  background: url("../assets/survey/男@2x.png") no-repeat;
}
.icon5 {
  background: url("../assets/survey/女@2x.png") no-repeat;
}
.icon6 {
  background: url("../assets/survey/党支部图标@2x.png") no-repeat;
}
.icon7 {
  background: url("../assets/survey/党员图标@2x.png") no-repeat;
}
.icon8 {
  background: url("../assets/survey/GDP图标@2x.png") no-repeat;
}
.icon9 {
  background: url("../assets/survey/人均GDP@2x.png") no-repeat;
}
.img1 {
  background: url("../assets/survey/1@2x.png") no-repeat;
}
.img2 {
  background: url("../assets/survey/2@2x.png") no-repeat;
}
.img3 {
  background: url("../assets/survey/3@2x.png") no-repeat;
}
.img4 {
  background: url("../assets/survey/4@2x.png") no-repeat;
}
.guihua {
  padding: 20px 0;
  font-size: 18px;
  line-height: 25px;
}
.guihua-img {
  display: flex;
  justify-content: center;
  height: calc(100% - 138px);
  img {
    height: 100%;
  }
  .my-swipe {
    width: 100%;
    & /deep/ .van-swipe-item {
      text-align: center;
    }
  }
}
</style>

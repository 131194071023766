import fetch from "@/utils/service";

export function getVideos(params) {
  return fetch.post("/v1.0/api/video/getVideos", params);
}
export function getVideo(params) {
  return fetch.post("/v1.0/api/video/getVideo", params);
}

export function getConfig(params) {
  return fetch({ url: "/v1.0/api/village/get_big_config", params });
}

export function getHome(params) {
  return fetch({ url: "/v1.0/api/village/homepage_mini", params });
}




import service from "@/utils/service";

export function getHomepage(params) {
    return service.post(`/v1.0/api/village/homepage`, params);
}
export function getPartyMemberList(params) {
    return service({
        url: `/v1.0/api/party_member/list`,
        params
    });
}
export function getCunLiangWei(params) {
    return service.post(`/v1.0/api/village/cun_liang_wei`, params);
}
export function getRoadMap(params) {
    return service.post(`/v1.0/api/roadmap/getRoadmaps`, params);
}

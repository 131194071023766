<template>
  <div class="header">
    <div class="textLeft">{{ slogan }}</div>
    <div class="textCenter">
      <div>乡村振兴 数字</div>
      <div class="name">{{ villageName }}</div>
    </div>
    <div class="textRight f-ai-c">
      <span>{{ pageNum }}</span>
      <span>翻页倒计时 {{ curCountDown }}s</span>
      <span class="nextBtn" @click="onShowNext()"></span>
    </div>
  </div>
</template>
<script>
export default {
  name: "header",
  components: {},
  props: {
    pageNum: {
      type: String,
      required: true,
    },
    villageName: {
      type: String,
      required: true,
    },
    slogan: {
      type: String,
      required: true,
    },
    curCountDown: {
      type: String,
      required: true,
    }
  },
  data() {
    return {};
  },

  computed: {},

  methods: {
      onShowNext(){
          this.$emit('onShowNext')
      }
  },
};
</script>
<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: h(100);
  width: 100%;
  background: url("../../assets/common/大标题_底板@2x.png") no-repeat;
  background-size: 100% 100%;
  color: #56fefe;
  font-size: 24px;
  .textLeft,
  .textRight {
    margin-bottom: h(27);
  }
  .textLeft {
    margin-left: 100px;
  }
  .textRight span:nth-child(1) {
    font-size: h(18);
    margin-right: h(13);
    margin-top: h(3);
  }
  .textRight span:nth-child(2) {
    font-size: h(22);
    margin-right: h(13);
  }
  .textRight span:nth-child(3) {
    width: 101px;
    height: h(34);
    font-size: h(22);
    background: url("../../assets/common/下一页按钮@2x.png") no-repeat;
    background-size: 100% 100%;
    margin-right: h(20);
  }
  .textRight {
    display: flex;
    margin-right: 20px;
    span {
      display: inline-block;
      color: #56fefe;
    }
    .pageNum {
      margin-left: 10px;
      display: flex;
      justify-content: center;
      width: 96px;
      height: h(40);
      line-height: h(40);
      background: #0c2b46;
      border-radius: 20px;
      border: 1px solid #01f9ed;
    }
  }
  .textCenter {
    margin-bottom: h(10);
    display: flex;
    font-size: 34px;
    .name {
      margin-left: 10px;
      display: flex;
      justify-content: center;
      line-height: h(53);
      font-size: 32px;
      font-weight: 700;
      width: 126px;
      height: h(53);
      background: url("../../assets/common/选中@2x.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  .nextBtn {
  }
}
</style>
